
























































import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { Contract } from '../models/contract.model';
import { ContractAmountType } from '@/shared/enums/contract-amount-type.enum';
import { ToastHelper } from '../../../shared/helpers/toast.helper';
import { DateHelper } from '../../../shared/helpers/date.helper';
import { PriceHelper } from '../../../shared/helpers/price.helper';

@Component
export default class Dashboard extends Vue {
  loaded = false;
  showOld = false;

  @Action
  private loadContracts!: (payload: { showExpired: boolean }) => Promise<void>
  @Action
  private setLoading!: (loading: boolean) => Promise<void>


  @Getter
  contractList!: Contract[];

  async created() : Promise<void> {
    await this.realoadContracts();
  }

  async realoadContracts() : Promise<void> {
    try {
      await this.setLoading(true);
      this.loaded = false;

      await this.loadContracts({
        showExpired: this.showOld
      })
    }
    catch (ex) {
      ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
    }
    finally {
      await this.setLoading(false);
      this.loaded = true;
    }
  }

  getDate(val: string | null) : string {
    if (val === null) {
      return '-';
    }

    return  DateHelper.parseDate(val, 'DD-M-YYYY');
  }

  toPrice(value: number) : string {
    return PriceHelper.getPrice(value);
  }

  getAmountType(value?: ContractAmountType | null) : string {
    switch (value) {
        case ContractAmountType.PerHour:
            return `${this.$t('shared.contractAmountType.perHour')}`;
        case ContractAmountType.PerPeriod:
            return `${this.$t('shared.contractAmountType.perPeriod')}`;
        case ContractAmountType.PerItem:
            return `${this.$t('shared.contractAmountType.perItem')}`;
        case ContractAmountType.NoCompensation:
            return `${this.$t('shared.contractAmountType.noCompensation')}`;
        default:
            return '';
    }
  }

  get tableHeaders(): { key: string, label: string }[] {  
    return [
      { key: 'startDate', label: `${this.$t('contracts.list.table.startDate')}` },
      { key: 'endDate', label: `${this.$t('contracts.list.table.endDate')}` },
      { key: 'name', label: `${this.$t('contracts.list.table.name')}` },
      { key: 'category', label: `${this.$t('contracts.list.table.category')}` },
      { key: 'amount', label: `${this.$t('contracts.list.table.amount')}` },
      { key: 'amountType', label: `${this.$t('contracts.list.table.amountType')}` },
      { key: 'responsibleUser', label: `${this.$t('contracts.list.table.responsibleUser')}` },
      { key: 'id', label: '' },
    ]
  }

  get tableValues() : Contract[] {
    return this.contractList;
  }
}
