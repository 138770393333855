var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("div", { staticClass: "container" }, [
        _c("h5", { staticClass: "col-12 mt-3" }, [
          _vm._v(_vm._s(_vm.$t("contracts.list.title"))),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 mb-4 d-flex justify-content-end" },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: { name: "check-button", switch: "" },
                  on: { change: _vm.realoadContracts },
                  model: {
                    value: _vm.showOld,
                    callback: function ($$v) {
                      _vm.showOld = $$v
                    },
                    expression: "showOld",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("contracts.list.showOld")) + " ")]
              ),
            ],
            1
          ),
        ]),
        _vm.contractList.length === 0
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col mb-3" }, [
                _c(
                  "div",
                  {
                    staticClass: "alert alert-warning",
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("contracts.list.noContracts")) + " "
                    ),
                  ]
                ),
              ]),
            ])
          : _c(
              "div",
              { staticClass: "row" },
              [
                _c("b-table", {
                  attrs: {
                    striped: "",
                    hover: "",
                    items: _vm.tableValues,
                    fields: _vm.tableHeaders,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(startDate)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getDate(data.item.startDate)) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(endDate)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.getDate(data.item.endDate)) + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(amount)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " € " + _vm._s(_vm.toPrice(data.value)) + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(amountType)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.getAmountType(data.value)) + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(responsibleUser)",
                        fn: function (data) {
                          return [_vm._v(" " + _vm._s(data.value) + " ")]
                        },
                      },
                      {
                        key: "cell(id)",
                        fn: function (data) {
                          return [
                            _c(
                              "router-link",
                              {
                                staticClass: "btn btn-primary",
                                attrs: {
                                  to: {
                                    name: "ContractDetails",
                                    params: { id: data.value },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("shared.details")) + " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3591025714
                  ),
                }),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }